<template>
  <div>
    <b-list-group-item >
      <b-row>
        <b-col class="menu-tab">
          <p v-b-toggle.collapse-3>{{ property.name }}</p>
        </b-col>
        <b-col>
          <b-button-toolbar style="float:right">
            <b-button-group class="mx-1">
              <b-button @click="addNew" variant="success"><i class="flaticon2-add-1" ></i>Add Property</b-button>
              <b-button @click="editGroup(property.id)">Edit</b-button>
              <b-button @click="deleteGroup(property.id)" variant="danger">Delete</b-button>
            </b-button-group>
          </b-button-toolbar>
        </b-col>
      </b-row>
    </b-list-group-item>
    <b-collapse visible id="collapse-3">
      <b-card>
        <b-list-group>
          <b-list-group-item v-for="item in property.items">
            <b-row>
              <b-col>
                {{ item.name }} - {{$store.state.auth.currency}}{{ item.price }}
              </b-col>
              <b-col>
                <b-button-toolbar style="float:right">
                  <b-button-group size="sm">
                    <b-button @click="edit(item.id)">Edit</b-button>
                    <b-button @click="deleteItem(item.id)" variant="danger">Delete</b-button>
                  </b-button-group>
                </b-button-toolbar>
              </b-col>
            </b-row>
          </b-list-group-item>
        </b-list-group>
      </b-card>
    </b-collapse>

    <b-modal
      id="property-item-modal"
      size="lg"
      title="Add Property"
      ref="property-item-modal"
      hide-footer
    >
      <PropertyForm :group_id="property.id" @success="get" :id="currentId"></PropertyForm>
    </b-modal>
  </div>
</template>


<script>
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import PropertyForm from "@/view/pages/menu/form-components/PropertyForm";

export default {
  name: "PropertyGroupList",
  props: ['property'],
  components: {
    PropertyForm,
  },
  data() {
    return {
      currentId: '',
    }
  },
  mounted() {
    //
  },
  methods: {
    get()
    {
      this.hideModal()
      this.$emit('success');
    },
    hideModal() {
      this.currentId = '';
      this.$refs["property-item-modal"].hide();
    },
    addNew() {
      this.currentId = '';
      this.$refs["property-item-modal"].show();
    },
    edit(id) {
      this.currentId = id;
      this.$refs["property-item-modal"].show();
    },
    editGroup(id) {
      this.$emit('group-edit', id)
    },
    deleteItem(id) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.delete('business/menu/properties/'+id)
          .then(({ data }) => {
            this.get();
            Swal.fire(
              'Deleted!',
              'Topping has been deleted.',
              'success'
            )
          });
        }
      })
    },
    deleteGroup(id) {
      this.$emit('group-delete', id)
    }
  }
};
</script>
